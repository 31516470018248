import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { Button, CardHeader, Divider, CardMedia, Card, Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

function createData(pregunta, categoria, opcion, puntaje) {
  return {
    pregunta,
    respuesta: 
      {
        categoria: categoria,
        opcion: opcion,
        puntaje: puntaje,
      }
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.pregunta}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
                {/* <Typography variant="h6" gutterBottom component="div">
                    History
                </Typography> */}
                <List dense >
                    <ListItem>
                        {/* <ListItemText sx={{width:'50%'}} secondary='Categoría' /> */}
                        <ListItemText  secondary={row.respuesta?.categoria} primary='Categoría'/>
                        {/* <ListItemText primary="Photos" secondary="Jan 9, 2014" /> */}
                    </ListItem>

                    <ListItem>
                        {/* <ListItemText sx={{width:'50%'}} secondary='Respuesta' /> */}
                        <ListItemText secondary={row.respuesta?.opcion} primary='Respuesta'/>
                    </ListItem>

                    <ListItem>
                        {/* <ListItemText sx={{width:'50%'}} secondary='Puntaje' /> */}
                        <ListItemText sx={{width:'50%'}} secondary={row.respuesta?.puntaje} primary='Puntaje'/>
                    </ListItem>
                </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    pregunta: PropTypes.string.isRequired,
    respuesta: 
      PropTypes.shape({
        categoria: PropTypes.string.isRequired,
        opcion: PropTypes.string,
        puntaje: PropTypes.number,
        
      }),
    
  }).isRequired,
};

function RowVideo({videoUrl}) {
    const [open, setOpen] = React.useState(false);
    // useEffect(()=>console.log(videoUrl));
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            Video de presentación
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                  {videoUrl !== ''? 
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component='video'
                                image={videoUrl}
                                
                                controls
                            />
                        </Card>
                        
                        :
                        <Alert severity="info">No se grabó video de presentación </Alert>
                  }
                
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

export default function DataUser({data, set_view}) {

    const [name, set_name] = useState('');
    const [email, set_email] = useState('');
    const [video_url, set_video_url] = useState('');
    const [preguntas, set_preguntas] = useState([]);

    useEffect(()=>{
        if(data){
            // console.log(data);
            const new_preguntas = [];
            for(const cat of data.respuestas){

                for(const resp of cat.respuestas){
                    const pregunta = resp.pregunta;
                    const categoria = cat.categoria;
                    const opcion = resp.respuesta || 'No se eligió ninguna opción';
                    const puntaje = +resp.puntaje || 0;
                    const new_data_question = createData(pregunta, categoria, opcion, puntaje);
                    new_preguntas.push(new_data_question);
                }
            }

            set_name(data.nombre || '');
            set_email(data.email || '');
            set_video_url(data.videoUrl);
            set_preguntas(new_preguntas);
        }
    },[data]);

    return (
        <TableContainer component={Paper}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems:'center',
                    p: 2
                }}
            >
                <Button
                    // disabled={loading_create}
                    sx={{ml:3}}
                    onClick={()=>set_view('users')}
                    component="a"
                    startIcon={<ArrowBackIcon fontSize="small" />}
                >
                    Regresar
                </Button>  
                
                <CardHeader sx={{textAlign:'right'}} subheader={email} title={name}/>
            </Box>
        
            <Divider />
            
        <Table aria-label="collapsible table">
            <TableHead>
            <TableRow>
                <TableCell />
                <TableCell>Listado de preguntas</TableCell>
                
            </TableRow>
            </TableHead>
            <TableBody>
            {preguntas.map((row, key) => (
                <Row key={key} row={row} />
            ))}
            <RowVideo videoUrl={video_url}></RowVideo>
            </TableBody>
        </Table>
        </TableContainer>
    );
}