import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import Slide from '@mui/material/Slide';

import { secondaryAuth } from '../../Firebase/FirebaseConfig';
import { signInWithEmailAndPassword , deleteUser } from 'firebase/auth'
import { db } from '../../Firebase/FirebaseConfig';
import { doc, deleteDoc } from "firebase/firestore";

import Notification from '../Notification/Notification';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteUserModal({open, set_open, userData, onDelete}) {
//   const [open, setOpen] = useState(false);
    const [loading_delete, set_loading_delete] = useState(false);

    const [open_notification, set_open_notification] = useState(false);
    const [message_notification, set_message_notification] = useState('');
    const [severity_notification, set_severity_notification] = useState('');

    const openNotification = (message_, severity_)=>{
        set_message_notification(message_);
        set_severity_notification(severity_);
        set_open_notification(true);
        set_open_notification(false);
    }

    useEffect(()=>{
        // console.log(userData);
    },[userData]);

    const deleteUserDB = (uid)=>{
        deleteDoc(doc(db, "usuarios_app", uid))
        .then(()=>{
            // alert('usuario eliminado correctamente');
            openNotification('Usuario eliminado correctamente', 'success');
            set_open(false);
            onDelete();
            set_loading_delete(false);
        })
        .catch((error)=>{
            // alert("Lo sentimos, \nNo se pudo eliminar este usuario de la base de datos. \nInténtalo nuevamente\n" + error);
            openNotification("Lo sentimos, No se pudo eliminar este usuario de la base de datos. Inténtalo nuevamente " + error, 'error');
            deleteUserDB(uid);
            
        });
    }

    const deleteUserData = () =>{
        set_loading_delete(true);
        signInWithEmailAndPassword(secondaryAuth, userData.email, userData.contrasena)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;

            //Eliminar el usuario de autenticación
            deleteUser(user).then(() => {
                // Usuario eliminado de la autenticación de firebase
                deleteUserDB(user.uid);

                // deleteDoc(doc(db, "usuarios_app", user.uid))
                // .then(()=>{
                //     // alert('usuario eliminado correctamente');
                //     set_open(false);
                //     onDelete();
                //     set_loading_delete(false);
                // })
                // .catch((error)=>{

                //     alert("Lo sentimos, \nNo se pudo eliminar este usuario de la base de datos. \nInténtalo nuevamente.");
                // });

            }).catch((error) => {
                // alert("Lo sentimos, \nNo se pudo eliminar este usuario. \nInténtalo nuevamente.");
                openNotification("Lo sentimos, No se pudo eliminar este usuario de la base de datos. Inténtalo nuevamente " + error, 'error');
                // console.log('1',error);
            });
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage);
            set_open(false);
            // onDelete();
            set_loading_delete(false);
        });
    } 

  

    const handleClickOpen = () => {
        set_open(true);
    };

    const handleClose = () => {
        set_open(false);
    };

    return (
        <div>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
            Slide in alert dialog
        </Button> */}
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Advertencia"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                {`¿Está seguro que desea eliminar al usuario ${userData.nombre} ?`}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <LoadingButton 
                    color="error"
                    
                    component="span"
                    onClick={deleteUserData}
                    endIcon={<DeleteIcon />}
                    loadingPosition="end"
                    loading={loading_delete}
                >
                    {loading_delete? 'Eliminando' : 'Eliminar'}
            </LoadingButton >
            {/* <Button onClick={deleteUserData}>Eliminar</Button> */}
            </DialogActions>
        </Dialog>

        <Notification message={message_notification} severity={severity_notification} open_flag={open_notification}></Notification>
        </div>
    );
}